
import './App.css';
import React, { useEffect, useState, useContext, createContext, } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";

import { Login } from "./components/MediaInterface";
import { SubmitAccountRequest } from './components/MediaInterface';
import { SubmitArticle } from "./components/MediaInterface";
import { SubmitFighter, UpdateFighter } from './components/Admin/AdminFighter';
import { Fighter } from './components/Admin/AdminFighter';
import { SubmitEvent, SubmitEventTV, UpdateEvent, UpdateEventTV } from './components/Admin/AdminEvent';
import { SubmitOrga, UpdateOrga } from './components/Admin/AdminOrga';
import { Organisation } from './components/Admin/AdminOrga';
import { Combat } from './components/Admin/AdminCombat';
import { Event } from './components/Admin/AdminEvent';
import { SubmitCombat, UpdateCombat } from './components/Admin/AdminCombat';
import { AccueilAdmin } from './components/Admin/AccueilAdmin';
import Account from './components/MediaInterface';
import { ArticleDetail } from './components/MediaInterface';
import { UpdateRankings } from './components/Admin/AdminRankings';
import { Live } from './components/Admin/Live';
import { PdfGuidelines } from './components/MediaInterface';
import { storage } from './firebase';
export const ArticlesContext = React.createContext([]);

const AdminRoleContext = createContext();

export function useAdminRole() {
  return useContext(AdminRoleContext);
}

export function AdminRoleProvider({ children }) {
  const [email, setEmail] = useState(localStorage.getItem('email') || null);
  const [adminRole, setAdminRole] = useState(() => {
    // Récupérez le rôle du localStorage lors de l'initialisation de l'état
    return localStorage.getItem('adminRole') || null;
  });

  const value = {
    adminRole,
    setAdminRole, email, setEmail, storage
  };

  return <AdminRoleContext.Provider value={value}>{children}</AdminRoleContext.Provider>
}

function App() {

  return (
    <AdminRoleProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/PdfGuidelines" element={<PdfGuidelines />} />
          <Route path="/submitAccountRequest" element={<SubmitAccountRequest />} />
          <Route path="/submitArticle" element={<SubmitArticle />} />
          <Route path="/AccueilAdmin" element={<AccueilAdmin />} />
          <Route path="/submitOrga" element={<SubmitOrga />} />
          <Route path="/updateOrga" element={<UpdateOrga />} />
          <Route path="/Fighter" element={<Fighter />} />
          <Route path="/Combat" element={<Combat />} />
          <Route path="/Event" element={<Event />} />
          <Route path="/Organization" element={<Organisation />} />
          <Route path="/submitFighter" element={<SubmitFighter />} />
          <Route path="/updateFighter" element={<UpdateFighter />} />
          <Route path="/submitEvent" element={<SubmitEvent />} />
          <Route path="/updateEvent" element={<UpdateEvent />} />
          <Route path="/submitEventTV" element={<SubmitEventTV />} />
          <Route path="/updateEventTV" element={<UpdateEventTV />} />
          <Route path="/submitCombat" element={<SubmitCombat />} />
          <Route path="/updateCombat" element={<UpdateCombat />} />
          <Route path="/Rankings" element={<UpdateRankings />} />
          <Route path="/live" element={<Live />} />
          <Route path="/account" element={<Account />} />
          <Route path="/articles/:articleId" element={<ArticleDetail />} />
          <Route path="/" element={<Navigate to="/login" replace />} />
          
        </Routes>
      </Router>
    </AdminRoleProvider>
  );
}


export default App;
